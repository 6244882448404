export const routeMap = {
  /**
   * We maintain an object of logical page names to their
   * URL pathnames
   */
  forgotPassword: '/forgot-password',
  home: '/',
  login: '/login',
  manageSubscriptions: '/manage-subscriptions',
  news: '/news',
  analysis: '/analysis',
  bookmarks: '/bookmarks',
  business: '/business',
  offerCodeEntry: '/offer-code',
  corporateCode: (slug: string) => `/corporate/code/${slug}`,
  onboardingForm: '/onboarding',
  onboardDownload: '/onboard/download',
  openSourceLicenceInfo: '/open-source-licence-info',
  plansCheckoutSuccess: '/plans/checkout/success',
  profile: '/profile',
  plansCheckout: (slug?: string) => slug ? `/plans/checkout/${slug}` : '/plans/checkout',
  plansSignUp: (slug?: string) => slug ? `/plans/sign-up/${slug}` : '/plans/sign-up',
  plansLogin: (slug?: string) => slug ? `/plans/login/${slug}` : '/plans/login',
  signup: '/sign-up',
  join: '/join', 
  tags: (slug: string) => `/tags/${slug}`,
  search: `/search`,
  experts: (slug: string) => `/experts/${slug}`,
  notFound: '/404',
  hubs: (slug: string) => `/hubs/${slug}`,
  CheckoutStatus: '/checkout-status',
  // Advisors and Education are permanent hubs, so we can hardcode their slugs
  hubshub: '/hubs/directory',
  education: '/hubs/education',

  markets: '/markets',
  assets: (slug: string) => `/markets/assets/${slug}`,
  sectors: (slug: string) => `/markets/sectors/${slug}`,
  industries: (slug: string, sectorSlug:string) => `/markets/sectors/${sectorSlug}/industries/${slug}`,

  /**
   * Rewrite routes to the webflow business site
   */
  privacy: '/privacy',
  faq: '/faq',
  glossary: '/glossary',
  glossaryDefinition: (slug: string) => `/glossary/${slug}`,
  terms: '/terms',
  careers: '/careers',
  about: '/about-us', 
  events: '/events', //Path is mapped to business site in next config

  /**
   * The following routeMap properties don't seem to be used, but are kept for backwards compatibility
   */
  newsletterSignup: '/newsletter/signup',  // still used via facebook links
  newsletterPreferences: '/newsletter/preferences',  // still used via facebook links
  newsletterThankyou: 'newsletter/thank-you',  // still used via facebook links
  newsletterPremiumSuccess: '/newsletter/premium/success',  // still used via facebook links
  newsletterPremium: '/newsletter/premium', // still used via facebook links
}