import { useState, useEffect, useCallback } from 'react'

import { useRouter } from 'next/router'
import { useDialogState } from 'reakit/Dialog'
import { css } from 'styled-components'
import styled from 'styled-components'

import { CONSENT_COOKIE_NAME } from './consts'

import { LegacyButton as Button } from 'components/Button'
import Card from 'components/Card'
import Column from 'components/Column'
import Columns from 'components/Columns'
import Heading from 'components/Heading'
import { LegacyLink as Link } from 'components/Link'
import Modal from 'components/Modal'
import Paragraph from 'components/Paragraph'
import Box from 'components/Primitives/Box'
import Set from 'components/Set'
import analyticsClient from 'lib/analyticsClient'
import initialiseStripe from 'lib/initialiseStripe'
import initialiseWebtrends from 'lib/initialiseWebtrends'
import appsFlyerPBAClient from 'lib/tracking/appsFlyerPBAClient'
import { useDatadogContext } from 'lib/tracking/datadog/datadogClient'
import fbTrackingClient from 'lib/tracking/fb/fbTrackingClient'
import { useFinanceadsContext } from 'lib/tracking/financeAds/useFinanceadsTracking'
import gaTrackingClient from 'lib/tracking/gaTrackingClient'
import { useImpactContext } from 'lib/tracking/impact/useImpactTracking'
import tikTokTrackingClient from 'lib/tracking/tiktokTrackingClient'
import { useCookies, DAY_IN_SECONDS } from 'lib/useCookies'
import { zIndex } from 'theme'

const buttonHoverStyle = css`
    &:hover {
        color: #fff;
    }
`

const CookieModal = () => {
    const { cookies, setCookie } = useCookies()
    const [showSettings, setShowSettings] = useState(false)
    const dialog = useDialogState()
    const router = useRouter()
    const optOutQueryParam = router.query?.['ios-tracking-optout']

    const datadogClient = useDatadogContext()

    const impactClient = useImpactContext()
    const financeadsClient = useFinanceadsContext()

    const authorizeTracking = useCallback(async () => {
        // NOTE All tracking clients must be authorized here.
        datadogClient.authorize()
        analyticsClient.authorize()
        fbTrackingClient.authorize()
        gaTrackingClient.authorize()
        tikTokTrackingClient.authorize()
        appsFlyerPBAClient.authorize()
        impactClient.authorize()
        financeadsClient.authorize()
        initialiseStripe()
        initialiseWebtrends()
    }, [])

    const hideCookieModal = useCallback(({ accepted }: { accepted: boolean }): void => {
        if (accepted) authorizeTracking()

        setCookie(CONSENT_COOKIE_NAME, String(accepted), {
            maxAge: 30 * DAY_IN_SECONDS, // 30 days
        })

        dialog.setVisible(false)
    }, []) // useState's semantics guarantee `setVisible` won't change

    const acceptCookieModal = useCallback(
        () => hideCookieModal({ accepted: true }),
        [hideCookieModal],
    )
    const declineCookieModal = useCallback(
        () => hideCookieModal({ accepted: false }),
        [hideCookieModal],
    )

    const displaySettings = useCallback(() => setShowSettings(true), [setShowSettings])
    const goBack = useCallback(() => setShowSettings(false), [setShowSettings])

    useEffect(() => {
        // This condition will obtain on the first invocation of useCookies.
        // YOU MUST NOT RELY ON USECOOKIES BEING CORRECT AT EACH INVOCATION.
        // Due to race conditions, it is 'eventually correct', with a null
        // result indicating its state is still inconsistent.
        if (cookies == null) return

        const { cookie_consent: cookieConsent } = cookies
        if (!cookieConsent) return dialog.setVisible(true)

        let haveConsent = false
        try {
            haveConsent = JSON.parse(cookieConsent)
        } catch {}

        if (haveConsent === true) {
            authorizeTracking()
        }
    }, [cookies])

    const buttonPadding = ['1.25rem !important', '1.5rem !important']

    const heading = showSettings ? 'Change cookie settings' : 'This website uses cookies'

    if (optOutQueryParam && optOutQueryParam === 'true') {
        return null
    }

    const ActionCTAs = showSettings ? (
        <>
            <Button
                isBlock
                onClick={declineCookieModal}
                color="danger"
                p={buttonPadding}
                tabIndex={1}
            >
                Decline all cookies
            </Button>
            <Button
                isBlock
                isOutlined
                onClick={goBack}
                p={buttonPadding}
                color="grey800"
                css={buttonHoverStyle}
                tabIndex={1}
            >
                Go back
            </Button>
        </>
    ) : (
        <>
            <Button isBlock onClick={acceptCookieModal} p={buttonPadding} tabIndex={1}>
                Allow cookies 🍪
            </Button>
            <Button
                isBlock
                isOutlined
                onClick={displaySettings}
                p={buttonPadding}
                color="grey800"
                css={buttonHoverStyle}
                tabIndex={1}
            >
                Change cookie settings
            </Button>
        </>
    )

    return (
        <StyledModal
            baseId={dialog.baseId}
            visible={dialog.visible}
            ariaLabel="Cookie consent Modal"
        >
            <Box display="flex" alignItems="center" width="100%" p={2} data-cy="cookie-modal">
                <Card elevation={2} radius="medium" maxWidth="475px" p={2} margin="auto">
                    <Columns alignItems="center" flexDirection="column">
                        <Column>
                            <Box pb={[0, 1]} pr={[1, 0]}>
                                <Heading fontSize={['16px', '20px']}>{heading}</Heading>
                                <Paragraph fontSize="14px" fontWeight="500" lineHeight="28px">
                                    We use them to offer you a better browsing experience, enable
                                    social media features, personalise ads, and to understand our
                                    audience.{' '}
                                    <Link
                                        url="https://www.finimize.com/wp/privacy-policy/"
                                        target="_blank"
                                        tabIndex={1}
                                    >
                                        Our privacy policy
                                    </Link>
                                    .
                                </Paragraph>
                            </Box>
                        </Column>
                        <Column spread={12}>
                            <Set spacing={1} isVertical>
                                {ActionCTAs}
                            </Set>
                        </Column>
                    </Columns>
                </Card>
            </Box>
        </StyledModal>
    )
}

const StyledModal = styled(Modal)`
    z-index: ${zIndex.layerTop}; // This is to ensure the modal is always on top of everything else, e.g. the navigation bars
`

export default CookieModal
